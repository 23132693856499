import React from "react";
import Cards from '../../Components/Cards';
import '../../Styles/Main.css';




function Main() {
  return (
    <div>
      <Cards />
    </div>
  );
  }
  
  export default Main;
  