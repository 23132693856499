import React from "react";
//import FormGroupExample from '../../Components/LoginForm';
import "../../Styles/LoginPage.css"


function LoginPage() {

    return (
        <div className="Login">
            <h1>Coming soon</h1>
           {/*<FormGroupExample/> Soon available*/} 
        </div>
    )
}

export default LoginPage() 